import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { TabList, Input, Textarea, Button, FormGroupList, StaticFormGroup } from 'tyb';
import { FormGroupField } from 'tyb';
import { reduxForm } from 'redux-form';
const { customFieldsById, customFieldsPreview, customFieldsModify } = iceStarkData.store.get('commonAction')?.customFields;
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;

import customCommon from '../../../../assets/custom-common.png';
import customClass from '../../../../assets/custom-class.png';

import '../index.scss';

/* 
    自定义格式
*/
@connect(
    state => ({
        form: state.form.CustomFormatViewForm,
        permissionIds: state.users.permissionIds||[],
        loading: state.customFields.loading,
        previewLoading: state.customFields.previewLoading,
        customFieldsById: state.customFields.customFieldsById,
        customFieldsPreview: state.customFields.customFieldsPreview,
        customFieldsModifyLoaded: state.customFields.customFieldsModifyLoaded,
    }),
    {
        getCustomFieldsById: customFieldsById.REQUEST,
        setCustomFieldsPreview: customFieldsPreview.REQUEST,
        setCustomFieldsModify: customFieldsModify.REQUEST,
        setNavBar,
    }
)
class CustomFormatView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 'COMMON_MODE',
            previewValue: ''
        };
        this.tabList = [{ text: '标准模式', value: 'COMMON_MODE' }, { text: '高级模式', value: 'CLASSIS_MODE' }]
    }

    componentDidMount() {
        const { history } = this.props;
        const parsed = queryString.parse(history.location.search);
        const { id } = parsed;
        this.props.setNavBar(id ? '查看' : '创建');
        if (id) {
            this.props.getCustomFieldsById({ id });
        }
        this.setState({ id });
    }

    componentDidUpdate(preProps) {

        if (this.props.customFieldsPreview && this.props.customFieldsPreview !== preProps.customFieldsPreview) {
            this.setState({ previewValue: this.props.customFieldsPreview.value || '' });
        }

        if (this.props.customFieldsById && this.props.customFieldsById !== preProps.customFieldsById) {
            this.props.initialize(this.props.customFieldsById);
            this.setState({ currentTab: this.props.customFieldsById.mode || 'COMMON_MODE' });
        }

        if ((/2\d{2}$/.test(this.props.customFieldsModifyLoaded) && this.props.customFieldsModifyLoaded !== preProps.customFieldsModifyLoaded)) {
            this.props.history.push(`/codecustomFields`);
        }

    }

    // 切换 tab
    handleSwitchTab = (currentTab) => {
        if (this.state.id) return;
        this.setState({ currentTab, previewValue: '' });
    }

    // 预览
    handlePreview = () => {
        const { form: { values: { fieldValue } } } = this.props;
        if (fieldValue) {
            this.props.setCustomFieldsPreview({ fieldValue, mode: this.state.currentTab });
        }
    }

    // 提交
    handleSubmit = (data) => {
        const { id, fieldName, fieldValue, fieldCode } = data;
        const formData = new FormData();
        if (id) {
            formData.append('id', id);
        }
        formData.append('fieldName', fieldName);
        formData.append('fieldValue', fieldValue);
        formData.append('fieldCode', fieldCode);
        formData.append('mode', this.state.currentTab);
        this.props.setCustomFieldsModify({id, formData});
    }

    render() {
        const { permissionIds, handleSubmit, previewLoading, loading } = this.props;
        const { currentTab, id, previewValue } = this.state;
        return (
            <div>
                <div className="container CustomFormatView">
                    <TabList
                        list={this.tabList}
                        value={currentTab}
                        onChange={this.handleSwitchTab}
                    >
                    </TabList>
                    <FormGroupList>
                        <FormGroupField
                            name="fieldName"
                            label="字段名称"
                            component={Input}
                            disabled={id}
                            required
                        />
                        {currentTab == 'CLASSIS_MODE' && <StaticFormGroup
                            label="字段编写"
                            required
                        >
                            <span style={{ color: '#444' }}>
                                {'public String convert(String url, String domain, String subDomain, String code, long seqNum, String mixed16){'}
                            </span>
                        </StaticFormGroup>}
                        <FormGroupField
                            name="fieldValue"
                            label={currentTab == 'COMMON_MODE' ? '字段编写' : ''}
                            component={Textarea}
                            disabled={id}
                            required={currentTab == 'COMMON_MODE'}
                        />
                        <FormGroupField
                            name="fieldCode"
                            label="字段代码"
                            component={Input}
                            disabled={id}
                            required
                        />
                        {permissionIds.includes('dm.customFields.preview') && <li>
                            <div className="form-label">
                                <Button className="tc-15-btn weak" loading={previewLoading} onClick={this.handlePreview}>预览</Button>
                            </div>
                            <div className="form-input">
                                <pre style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>{previewValue}</pre>
                            </div>
                        </li>}

                        <li>
                            <div className="form-label">
                                <label>字段说明</label>
                            </div>
                        </li>
                    </FormGroupList>
                    <img className="custom-common" style={{ display: currentTab == 'COMMON_MODE' ? 'block' : 'none' }} src={customCommon} />
                    <img className="custom-class" style={{ display: currentTab == 'CLASSIS_MODE' ? 'block' : 'none' }} src={customClass} />
                    <div className="btn-group">
                        {permissionIds.includes('dm.customFields.add') && !id && <Button onClick={handleSubmit(this.handleSubmit)} loading={loading}>保存</Button>}
                        <Button className="tc-15-btn weak" onClick={() => { this.props.history.push('/codecustomFields') }}>返回</Button>
                    </div>
                </div>
            </div>
        )
    }
}

const validate = (values, props) => {
    const errors = {};

    if (!values.fieldName || !values.fieldName.trim()) {
        errors.fieldName = '请输入字段名称';
    } else if (values.fieldName.trim().length > 30) {
        errors.fieldName = '长度不超过30个字符';
    }
    if (!values.fieldValue) {
        errors.fieldValue = '请输入字段编写';
    }

    if (!values.fieldCode) {
        errors.fieldCode = '请输入字段代码';
    }

    return errors;
}


export default reduxForm({
    form: 'CustomFormatViewForm',
    initialValues: {

    },
    validate
})(CustomFormatView)
